import React from 'react';

import './SectionCatalogue.scss';

export default function SectionCatalogue() {
  return (
    <div className="section-catalogue">
      <div className="container-2-columns">
        <div className="container-img">
          <img src="/img/catalogo.webp" alt="Catalogo"/>
        </div>
        <div>
          <div className="container-info-center">
            <div>
              <h2>
                 CATÁLOGO 2025
              </h2>
              <div>
                <p>
                Nuestro catálogo de sillas ergonómicas está renovado y listo para hacer de cada jornada una experiencia cómoda.  Descubre el match perfecto entre ergonomía y diseño.
                </p>
                <p>
                  Diseño, tecnología y eficiencia en cada uno de nuestros productos.
                </p>
              </div>
              <div className="mt-10">
                <a target="_blank" href="/files/Catalogo_Requiez_A24-25.pdf" className="btn">
                  Descubre Más
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}