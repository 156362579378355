import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import Admin from './Components/Pages/Admin/Admin';
import Home from "./Components/Pages/Home/Home";
import Categories from "./Components/Pages/Categories/Categories";
import Distributor from "./Components/Pages/Distributor/Distributor";
import Product from "./Components/Pages/Product/Product.jsx";
import Blog from "./Components/Pages/Blog/Blog";
import Note from "./Components/Pages/Note/Note";
import Search from './Components/Pages/Search/Search';
import Contact from './Components/Pages/Contact/Contact';
import Alliances from './Components/Pages/Alliances/Alliances';
import About from './Components/Pages/About/About';

import SsbContainer from './Components/PagesComponents/GeneralComponents/SsbContainer/SsbContainer';

import './App.scss';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: 'light',
          primary: {
            main: '#e50164'
          },
        },
      }),
    [prefersLightMode],
  );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <Switch>
              <Route path="/admin">
                <Admin/>
              </Route>
              <Route path="/categoria/:idCategory" children={
                <div>
                  <ScrollToTop />
                  <Categories />
                </div>
              } />
              <Route path="/producto/:idProduct" children={
                <div>
                  <ScrollToTop />
                  <Product />
                </div>
              } />
              <Route path="/blog/:idBlog" children={
                <div>
                  <ScrollToTop />
                  <Note />
                </div>
              } />
              <Route path="/products/:search" children={
                <div>
                  <ScrollToTop />
                  <Search />
                </div>
              } />
              <Route path="/distribuidores" children={
                <div>
                  <ScrollToTop />
                  <Distributor />
                </div>
              } />
              <Route path="/blog" children={
                <div>
                  <ScrollToTop />
                  <Blog />
                </div>
              } />
              <Route path="/alianzas" children={
                <div>
                  <ScrollToTop />
                  <Alliances />
                </div>
              } />
              <Route path="/nosotros" children={
                <div>
                  <ScrollToTop />
                  <About />
                </div>
              } />
              <Route path="/contacto" children={
                <div>
                  <ScrollToTop />
                  <Contact />
                </div>
              } />
              <Route path="/">
                <ScrollToTop />
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
