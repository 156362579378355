import { Component } from 'react';
import renderHTML from 'react-render-html';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";

import './SelectNote.scss';

class SelectNote extends Component {
  render() {
    return (
      <div className="select-note">
        <Link to={`/blog/${this.props.url}`}>
          <Card className="card">
            <CardActionArea>
              <CardMedia
                component="img"
                alt={this.props.title}
                className="img-note"
                image={`${this.props.urlImg}`}
                title={this.props.title}
              />
              <CardContent className="container-text">
                <Typography gutterBottom variant="h5" component="h2">                  
                    { renderHTML(this.props.title) }
                </Typography>
                <Typography variant="body2" className="summarize" component="p">
                    { renderHTML(this.props.summarize) }
                </Typography>
              </CardContent>
            </CardActionArea>
            {/* <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions> */}
          </Card>
        </Link>
      </div>
    );
  }
}


export default SelectNote;