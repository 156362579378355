import React from 'react';
import ReactDOM from 'react-dom/client'; // Cambia esto
import './index.scss';
import './config.js';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Crear el root usando React 18
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Medir el rendimiento de la aplicación
reportWebVitals();

// Hot Module Replacement (HMR), si está disponible
if (module.hot) {
  module.hot.accept();
}
