import { Component } from 'react';

import Container from '@material-ui/core/Container';
import ReCAPTCHA from "react-google-recaptcha";

import HomeIcon from '@material-ui/icons/Home';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import Header from './../../PagesComponents/Headers/Header';
import Footer from './../../PagesComponents/Footer/Footer';

import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";
import Banner from './../../PagesComponents/GeneralComponents/Banner/Banner';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import './Contact.scss';

const grecaptchaObject = window.grecaptcha

class Contact extends Component {

  state = {
    email: '',
    name: '',
    description: '',
    checkedMap: false,
    openMessage: false,
    messageAlert: '',
    iconMessage: '',
    recatcha: false
  }

  showMessage(message, icon) {
    this.setState({
      openMessage: true,
      messageAlert: message,
      iconMessage: icon
    });
  };

  hideMessage() {
    this.setState({
      openMessage: false
    });
  };


  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  async onChangeReCAPTCHA (ref, value) {
    ref.setState({
      recatcha: true
    });
    console.log('sdfsdffs', value);
  };

  sendEmail() {
    console.log({
      recatcha: this.state.recatcha, email: this.state.email, description: this.state.description
    });
    if (this.state.recatcha && this.state.email !== '' && this.state.description !== '') {
      var formData = new FormData();
      formData.append('action', 'correo');
      formData.append('email', this.state.email);
      formData.append('name', this.state.name);
      formData.append('message', this.state.description);

      fetch(`${global.config.URL.SERVER}/sendEmail`, {
        method: 'POST',
        body: formData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            email: '',
            description: '',
            name: ''
          });
          this.showMessage('Mensaje enviado', <CheckCircleIcon className="mr-2" />);
        } else {
          this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
        }
      })
      .catch(error => {
        console.log(error);
        this.showMessage('Error al enviar el mensaje', <ErrorIcon className="mr-2" />);
      });
    }
  }


  render() {
    return (
      <div className="contact-component">
        <Header/>
        <Banner inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Typography>Contacto</Typography>
          </Breadcrumbs>
          }
          bannerImage = {'categoria/contacto.png'}
          name = {'Contáctanos'}
        />
        <Container maxWidth="lg" className="mb-10">
          <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14941.840946926804!2d-103.4575194!3d20.5692543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dab4125a87ccdf2!2sGrupo%20Requiez%20S.A.%20De%20C.V.!5e0!3m2!1ses-419!2smx!4v1605219492895!5m2!1ses-419!2smx" className="map" frameBorder="0" style={{border: 0}} allowFullScreen="1" aria-hidden="false" tabIndex="0" title="Requiez Mapa"></iframe>
          </div>
          <div>
            <h2 className="contact-title">¡ENVÍANOS UN MENSAJE!</h2>
          </div>
          <div className="container-contact">
            <div>
              <form noValidate autoComplete="off">
                <TextField
                  className="input-form w-100"
                  label="Mensaje"
                  name="description"
                  multiline
                  maxRows={4}
                  value={this.state.description}
                  onChange={this.handleChange.bind(this)}
                />
                <TextField
                  className="input-form w-100"
                  label="Nombre"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange.bind(this)}
                />
                <TextField
                  className="input-form w-100"
                  label="Correo"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                />
                <div>
                  <ReCAPTCHA
                    ref={(r) => this.recaptcha = r}
                    sitekey="6Le4RWgaAAAAAPSMk2bLibrv1iEtzWpS_4VD_d68"
                    onChange={(value) => this.onChangeReCAPTCHA(this, value)}
                  />
                </div>
                <div className="text-right container-btn-send">
                  <Button className="btn-submit" onClick={this.sendEmail.bind(this)}>
                    Enviar
                  </Button>
                </div>
              </form>
            </div>
            <div>
              <div className="col-lg-3 offset-lg-1">
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    {/* <i className="ti-home"></i> */}
                    <HomeIcon className="ti-home" />
                  </span>
                  <div className="media-body">
                    <h3>Avenida Paseo Punto Sur #312 Nivel 1, Interior V2, Corporativo Punto Sur, Tlajomulco de Zúñiga, Jalisco, México.</h3>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <PhoneIphoneIcon className="ti-home" />
                  </span>
                  <div className="media-body">
                    <h3><a href="tel:+523338337788">+52 (33) 3833 7788</a></h3>
                    <p>Lunes a Viernes 9:00 am a 7:30 pm</p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <EmailIcon className="ti-home" />
                  </span>
                  <div className="media-body">
                  <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>
                    <p>Envíanos un mensaje en cualquier momento!</p><p>
                  </p></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Footer/>
        <Snackbar
          open={this.state.openMessage}
          onClose={this.hideMessage.bind(this)}
          onClick={this.hideMessage.bind(this)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              {this.state.iconMessage}
              {this.state.messageAlert}
            </span>
          }
        />
      </div>
    );
  }
}

export default Contact;