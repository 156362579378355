import { Component } from 'react';
import './SelectProduct.scss';
class SelectProduct extends Component {
  render() {
    return (
      <div className="select-product">
        <a href={`/producto/${this.props.url}`}>
          <div className="container-img">
            <div>
              <span className="img-main">
                <img src={`/img/products/${this.props.img}`} loading='lazy' alt={this.props.name}/>
              </span>
              <span className="img-hover">
                <img src={`/img/products/${this.props.imgHover}`} loading='lazy' alt={this.props.name}/>
              </span>
            </div>
          </div>
          <div className="text-center">
            <div className="name text-center">
              {this.props.name}
            </div>
            <div className="code text-center">
              {this.props.code}
            </div>
            { this.props.textiles && this.props.textiles.length > 0 && (
              <div className="textiles text-center">
                <span>
                  Tapices: 
                </span>
                {this.props.textiles.map((value, index) => {
                  return <span className="ml-1" key={index}>
                    <img src={`/img/${value.img}`} alt=""/>
                  </span>
                })}
              </div>
            )}
          </div>
        </a>
      </div>
    );
  }
}


export default SelectProduct;