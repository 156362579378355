import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

import SelectProduct from "./../../GeneralComponents/SelectProduct/SelectProduct";

import "./NewProducts.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "50px auto",
  },
}));

export default function NewProducts() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const previous = () => {
    if (value >= 2) {
      setValue(value - 1);
    } else {
      setValue(0);
    }
  };

  const next = () => {
    if (value < 2) {
      setValue(value + 1);
    } else {
      setValue(0);
    }
  };

  return (
    <div className="new-products-component" id="newProducts">
      <Container maxWidth="xl">
        <h1 className="text-center">Productos Destacados</h1>
        <Container maxWidth="md">
          <div className="text-center sub-title">
            Encuentra la solución adecuada para cada espacio y necesidad con el
            máximo confort y calidad. Diseño, tecnología y eficiencia en cada
            uno de nuestros productos.
          </div>
        </Container>

        <Container maxWidth="lg">
          <div className={classes.root}>
            <div className="container-new-products" >
              <div className="arrow previous">
                <IconButton
                  aria-label="previous"
                  onClick={previous}
                  color="primary"
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </div>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel
                  className="container-item"
                  value={0}
                  index={0}
                  dir={theme.direction}
                >
                  <div className="container-products">
                    <SelectProduct
                      img={"re-9100/ergohuman1.webp"}
                      imgHover={"re-9100/ergohuman5.webp"}
                      url={"re-9100"}
                      name={"Ergohuman"}
                      code={"RE-9100/NG"}
                    />
                    <Hidden xsDown>
                      <SelectProduct
                        img={"rm-9100-ng/enjoy1.webp"}
                        imgHover={"rm-9100-ng/enjoy2.webp"}
                        url={"re-9101-ng"}
                        name={"Enjoy NEGRA"}
                        code={" RE-9101-NG"}
                      />
                    </Hidden>

                    <Hidden xsDown>
                      <SelectProduct
                        img={"rm-9100-gr/enjoy1.webp"}
                        imgHover={"rm-9100-gr/enjoy2.webp"}
                        url={"re-9101-gr"}
                        name={"Enjoy GRIS"}
                        code={"RE-9101-GR"}
                      />
                    </Hidden>
                  </div>
                </TabPanel>
                <TabPanel
                  className="container-item"
                  value={1}
                  index={1}
                  dir={theme.direction}
                >
                  <div className="container-products">
                    <SelectProduct
                      img={"rp-6051-ng/zenit1.webp"}
                      imgHover={"rp-6051-ng/zenit4.webp"}
                      url={"rp-6051-ng"}
                      name={"ZENIT RESPALDO MEDIO NEGRO"}
                      code={"RP-6051/NG"}
                    />
                    <Hidden xsDown>
                      <SelectProduct
                        img={"rp-6051-cm/zenit1.webp"}
                        imgHover={"rp-6051-cm/zenit2.webp"}
                        url={"rp-6051-cm"}
                        name={"ZENIT RESPALDO MEDIO CAFÉ"}
                        code={"RP-6051/CM"}
                      />
                    </Hidden>
                    <Hidden xsDown>
                      <SelectProduct
                        img={"arlaBl/ArlaC-A.webp"}
                        imgHover={"arlaBl/ArlaC-B.webp"}
                        url={"re-3200bl"}
                        name={"ARLA ESTRUCTURA BLANCA"}
                        code={"RE-3200/BL"}
                      />
                    </Hidden>
                  </div>
                </TabPanel>
                <TabPanel
                  className="container-item"
                  value={2}
                  index={2}
                  dir={theme.direction}
                >
                  <div className="container-products">
                    <SelectProduct
                      img={"kayNg/kay-c-1.webp"}
                      imgHover={"kayNg/kay-c-2.webp"}
                      url={"re-2180ng"}
                      name={"KAY ESTRUCTURA NEGRA"}
                      code={"RE-2180/NG"}
                    />
                    <Hidden xsDown>
                      <SelectProduct
                        img={"leah/re-2160-ngA.webp"}
                        imgHover={"leah/re-2160-ngB.webp"}
                        url={"re-2160"}
                        name={"LEAH"}
                        code={"RE-2160/NG"}
                      />
                    </Hidden>
                    <Hidden xsDown>
                      <SelectProduct
                        img={"curveBl/curve-c-1.webp"}
                        imgHover={"curveBl/curve-c-2.webp"}
                        url={"re-1570bl"}
                        name={"CURVE ESTRUCTURA BLANCA"}
                        code={"RE-1570/BL"}
                      />
                    </Hidden>
                  </div>
                </TabPanel>
              </SwipeableViews>
              <div className="arrow next">
                <IconButton aria-label="next" onClick={next} color="primary">
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
}
