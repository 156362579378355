import React, { useState, useEffect } from 'react';

import Header from '../../PagesComponents/Headers/Header';
import Footer from '../../PagesComponents/Footer/Footer';
import Spinner from '../../PagesComponents/Spinner/Spinner.jsx';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation, useParams } from "react-router-dom";
import Banner from '../../PagesComponents/GeneralComponents/Banner/Banner';
import ProductGallery from '../../PagesComponents/SectionProduct/ProductGallery/ProductGallery';
import AccordionDetailsProduct from '../../PagesComponents/SectionProduct/AccordionDetailsProduct/AccordionDetailsProduct';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Instagram from '../../PagesComponents/GeneralComponents/Instagram/Instagram';

import './Product.scss';
import '@google/model-viewer';

const Product = () => {
  const { idProduct } = useParams();
  const location = useLocation();

  const [productInfo, setProductInfo] = useState({});
  const [category, setCategory] = useState({});
  const [imgsList, setImgsList] = useState([]);
  const [dataSheet, setDataSheet] = useState([]);
  const [dwgList, setDwgList] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [textileCategories, setTextileCategories] = useState([]);
  const [textiles, setTextiles] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [currentModel, setCurrentModel] = useState(null);

  const [loading3D, setLoading3D] = useState(true);
  useEffect(() => {
    getCategoryInfoAndProducts(idProduct);
  }, [idProduct, location.pathname]);

  const getCategoryInfoAndProducts = (idProduct) => {
    fetch(`${global.config.URL.SERVER}/product/${idProduct}`)
      .then(response => response.json())
      .then(data => {
        setProductInfo(data?.product || {});
        setCategory(data?.category || {});
        setImgsList(data?.imgs || []);
        setDataSheet(data?.dataSheet || []);
        setDwgList(data?.dwg || []);
        setAccessories(data?.accessories || []);
        setTextileCategories(data?.textileCategories || []);
        setTextiles(data?.textiles || []);
        setCertifications(data?.certifications || []);
        setCurrentModel(data?.dwg?.[0]?.img || null);
      })
      .catch(error => {
        console.error("Error al obtener los datos del producto:", error);
      });
  };

  const handleModelChange = (newModel) => {
      // setLoading3D(false);
      setCurrentModel(newModel);
  };

  return (
    <div className="product-component">
      <Header />
      <Banner
        inner={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">Inicio</Link>
            <Typography color="inherit">Productos</Typography>
            <Link color="inherit" to={`/categoria/${category.url}`}>{category.shortName}</Link>
            <Typography color="textPrimary">{productInfo.code}</Typography>
          </Breadcrumbs>
        }
        name={productInfo.name}
        bannerImage={category.imgBanner}
      />

      <Container maxWidth="lg" className="mb-10">
        <div id="container">
          <div id="sidebar">
            {imgsList.length > 0 && <ProductGallery imgMain={imgsList} />}
          </div>
          <div id="content">
            <div className="code-main">{productInfo.shortName}</div>
            <div>{productInfo.shortCode}</div>
            <div className="mb-3">
              <span className="mr-3">Categoría:</span>
              <span>
                <Link color="inherit" to={`/categoria/${category.url}`}>{category.shortName}</Link>
              </span>
            </div>
            <AccordionDetailsProduct
              description={productInfo.description}
              descontinuado={productInfo.descontinuado}
              colorMain={productInfo.colorMain}
              dataSheet={dataSheet}
              dwgList={dwgList}
              accessories={accessories}
              textileCategories={textileCategories}
              textiles={textiles}
              certifications={certifications}
            />
          </div>
        </div>
      </Container>

      {/* <div className="container mt-4 mb-4">
        {productInfo['3D'] === 1 && 
        (
          <div className='row'>
            <div className="col-lg-8">

              <h1>Configurador</h1>
                  {currentModel ? (
                    <model-viewer
                      src={`https://requiez.com/img/products/${currentModel}`}
                      alt="A 3D model of Zenit in black"
                      auto-rotate
                      camera-controls
                      ar
                      className="w-100"
                      style={{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#fff',
                      }}
                      exposure="0.8"
                      shadow-intensity="1"
                    ></model-viewer>
                  ) : (
                    <p>No hay modelos disponibles para este producto.</p>
                  )}
            </div>
            <div className="col-lg-4">
              <h2>Tapiz</h2>
              <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                {dwgList.map((model, index) => (
                  <div key={index} style={{ textAlign: 'center' }}>
                    <button
                      style={{
                        backgroundImage: `url(${model.description})`,
                        width: '80px',
                        height: '80px',
                        backgroundSize: 'cover',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      type='btn'
                      className='rounded-circle btn m-3'
                      onClick={() => handleModelChange(model.img)}
                    >
                    </button>
                    <br />

                      <span className='text-dark text center'>{model.name}</span>

                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div> */}

      <Instagram />
      <Footer />
    </div>
  );
};

export default Product;
